import React from "react"
import { useTable, useSortBy } from "react-table"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const OTeamStatsTable = props => {
  const { t } = useTranslation()
  const data = React.useMemo(() => props.tableData, [])
  const columns = React.useMemo(
    () => [
      {
        Header: t("Stats.JerseyNumberShort"),
        accessor: "nr",
        additionalStyles: "ef-squad-stats__header-cell--number",
      },
      {
        Header: t("Stats.LastName"),
        accessor: "lastname",
        additionalStyles: "ef-squad-stats__header-cell--last-name",
      },
      {
        Header: t("Stats.FirstName"),
        accessor: "firstname",
        additionalStyles: "ef-squad-stats__header-cell--first-name",
      },
      {
        Header: t("Stats.Position"),
        accessor: "position",
        additionalStyles: "ef-squad-stats__header-cell--number",
      },
      {
        Header: t("Stats.ShortNationality"),
        accessor: "nationality",
        additionalStyles: "ef-squad-stats__header-cell--nationality",
      },
      {
        Header: t("Stats.GamesPlayed"),
        accessor: "matches",
        additionalStyles: "ef-squad-stats__header-cell--matches",
      },
      {
        Header: t("Stats.Minutes"),
        accessor: "minutes",
        additionalStyles: "ef-squad-stats__header-cell--minutes",
      },
      {
        Header: t("Stats.Goals"),
        accessor: "goals",
        additionalStyles: "ef-squad-stats__header-cell--goals",
      },
      {
        Header: t("Stats.Assists"),
        accessor: "assists",
        additionalStyles: "ef-squad-stats__header-cell--assists",
      },

      {
        Header: (
          <span className="ef-data-table__header-cell-label">
            <svg className="ef-icon" width="16" height="16">
              <use
                href="#ef-icon--yellow-card-16"
                xlinkHref="#ef-icon--yellow-card-16"
              ></use>
            </svg>
          </span>
        ),
        accessor: "yellowCard",
        additionalStyles: "ef-squad-stats__header-cell--yellow-cards",
      },
      {
        Header: (
          <span className="ef-data-table__header-cell-label">
            <svg className="ef-icon" width="16" height="16">
              <use
                href="#ef-icon--2nd-yellow-card-16"
                xlinkHref="#ef-icon--2nd-yellow-card-16"
              ></use>
            </svg>
          </span>
        ),
        accessor: "secondYellowCard",
        additionalStyles: "ef-squad-stats__header-cell--2nd-yellow-card",
      },
      {
        Header: (
          <span className="ef-data-table__header-cell-label">
            <svg className="ef-icon" width="16" height="16">
              <use
                href="#ef-icon--red-card-16"
                xlinkHref="#ef-icon--red-card-16"
              ></use>
            </svg>
          </span>
        ),
        accessor: "redCard",
        additionalStyles: "ef-squad-stats__header-cell--red-card",
      },
    ],
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "nr",
            desc: false,
          },
        ],
      },
    },
    useSortBy
  )

  return (
    <table className="ef-data-table ef-squad-stats" {...getTableProps()}>
      <thead className="ef-data-table__header ef-squad-stats__header">
        {headerGroups.map((headerGroup, index) => (
          <tr
            className="ef-data-table__header-row ef-squad-stats__header-row"
            key={index}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, index) => (
              <th
                className={
                  "ef-data-table__header-cell ef-data-table__header-cell--sortable ef-squad-stats__header-cell " +
                  column.additionalStyles +
                  (column.isSorted
                    ? column.isSortedDesc
                      ? " --is-asc"
                      : " --is-desc"
                    : "")
                }
                key={index}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <span className="ef-data-table__header-cell-label">
                  {column.render("Header")}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="ef-data-table__body" {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return <OStatsTableRow row={row} key={i} />
        })}
      </tbody>
    </table>
  )
}

export default OTeamStatsTable

OTeamStatsTable.propTypes = {
  tableData: PropTypes.array,
}

OTeamStatsTable.defaultProps = {
  tableData: [],
}

export const OStatsTableRow = props => {
  const cells = props.row.cells

  const getValueByColumnHeader = id => {
    const cell = cells.find(c => c.column.id === id)
    return cell ? cell.value : 0
  }

  const getCellProps = id => {
    const cell = cells.find(c => c.column.id === id)
    return cell ? cell.getCellProps() : {}
  }

  return (
    <tr
      className="ef-data-table__row ef-data-table__row--link"
      {...props.row.getRowProps()}
    >
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--number"
        data-name="Nr."
        {...getCellProps("nr")}
      >
        {getValueByColumnHeader("nr")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--last-name"
        data-name="Name"
        {...getCellProps("lastname")}
      >
        {getValueByColumnHeader("lastname")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--first-name"
        data-name="Vorname"
        {...getCellProps("firstname")}
      >
        {getValueByColumnHeader("firstname")}
        <span className="ef-squad-stats__last-name-mobile">
          {" "}
          {getValueByColumnHeader("lastname")}
        </span>
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--position"
        data-name="Position"
        {...getCellProps("position")}
      >
        {getValueByColumnHeader("position")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--nationality"
        data-name="Nat."
        {...getCellProps("nationality")}
      >
        {getValueByColumnHeader("nationality")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--matches"
        data-name="Einsätze"
        {...getCellProps("matches")}
      >
        {getValueByColumnHeader("matches")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--minutes"
        data-name="Minuten"
        {...getCellProps("minutes")}
      >
        {getValueByColumnHeader("minutes")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--goals"
        data-name="Tore"
        {...getCellProps("goals")}
      >
        {getValueByColumnHeader("goals")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--assists"
        data-name="Assists"
        {...getCellProps("assists")}
      >
        {getValueByColumnHeader("assists")}
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--yellow-cards"
        {...getCellProps("yellowCard")}
      >
        <span>
          <svg className="ef-icon" width={16} height={16}>
            <use xlinkHref="#ef-icon--yellow-card-16" />
          </svg>
          <span>{getValueByColumnHeader("yellowCard")}</span>
        </span>
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--2nd-yellow-cards"
        {...getCellProps("secondYellowCard")}
      >
        <span>
          <svg className="ef-icon" width={16} height={16}>
            <use xlinkHref="#ef-icon--2nd-yellow-card-16" />
          </svg>
          <span>{getValueByColumnHeader("secondYellowCard")}</span>
        </span>
      </td>
      <td
        className="ef-data-table__cell ef-squad-stats__cell ef-squad-stats__cell--red-cards"
        {...getCellProps("redCard")}
      >
        <span>
          <svg className="ef-icon" width={16} height={16}>
            <use xlinkHref="#ef-icon--red-card-16" />
          </svg>
          <span>{getValueByColumnHeader("redCard")}</span>
        </span>
      </td>
    </tr>
  )
}
