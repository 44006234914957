/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import MShare from "gatsby-component-lib/components-legacy/molecules/m-share"
import {
  squadSubNavigation,
  clean_fullslug,
  peopleGrouper,
} from "gatsby-component-lib"
import AStatTableHeaderCell from "gatsby-component-lib/components-legacy/atoms/a-stat-table-header-cell"
import AStatTableBodyCell from "gatsby-component-lib/components-legacy/atoms/a-stat-table-body-cell"
import { Link } from "gatsby"
import OTeamStatsTable from "gatsby-component-lib/components-legacy/organisms/o-team-stats-table"
import OStadiumAdBlock from "gatsby-component-lib/components-legacy/organisms/o-stadium-ad-block"

export const query = graphql`
  query TeamStatisticsPageTemplateQuery($team_uid: String, $uid: String) {
    page: typo3Page(uid: { eq: $uid }) {
      content
    }
    squad: allTypo3Page(filter: { uid: { eq: $team_uid } }) {
      edges {
        node {
          uid
          slug
          full_slug
          type
          name
          data {
            season {
              id
              title
              slug
            }
            players {
              person {
                id
                uid
                first_name
                last_name
                slug
                full_slug
                position
                jersey_number
                country
                stats {
                  games_played
                  time_played
                  goals
                  goal_assists
                  yellow_cards
                  yellow_red_cards
                  red_cards
                }
              }
            }
          }
        }
      }
    }
  }
`
const TeamStatisticsPageTemplate = ({ data, pageContext }) => {
  const {
    node,
    website,
    website_lang,
    website_theme,
    tree,
    subnavigation,
    footerPageNodeContent,
  } = pageContext

  const { t } = useTranslation()
  let related_data = null
  if (data.squad.edges.length > 0) {
    // related_data = Ergebnis der Sub-Query (oben)
    related_data = data.squad.edges[0].node.data
  }

  let people = []
  let season = false
  if (related_data) {
    // Personen werden zuerst nach der Rückennummer sortiert
    people = related_data.players.sort(function(a, b) {
      return a.person.jersey_number < b.person.jersey_number ? -1 : 1
    })
    season = related_data.season
  }

  const content = JSON.parse(data.page.content)

  let full_slug = clean_fullslug(node.full_slug)
  let breadcrumbs = [
    {
      name: t("Websites." + website),
      full_slug: "/",
    },
    { name: t("Team"), full_slug: content.parents[0].full_slug },
  ]

  // DropDown oben rechts
  let subnavigationDropdown1 = []
  subnavigationDropdown1.push({
    link: full_slug,
    title: t("Dropdowns.AllPlayers"),
    active: false,
  })
  for (let i in people) {
    let p = people[i]
    subnavigationDropdown1.push({
      link: clean_fullslug(p.person.full_slug),
      title:
        p.person.jersey_number +
        " " +
        p.person.first_name +
        " " +
        p.person.last_name,
      active: false,
    })
  }

  const tableData = people.map(p => {
    let tableRow = {
      nr: p.person.jersey_number,
      lastname: p.person.last_name,
      firstname: p.person.first_name,
      position: peopleGrouper(p.person.position),
      nationality: p.person.country,
      matches: p.person.stats ? p.person.stats.games_played : 0,
      minutes: p.person.stats ? p.person.stats.time_played : 0,
      assists: p.person.stats ? p.person.stats.goal_assists : 0,
      goals: p.person.stats ? p.person.stats.goals : 0,
      yellowCard: p.person.stats ? p.person.stats.yellow_cards : 0,
      secondYellowCard: p.person.stats ? p.person.stats.yellow_red_cards : 0,
      redCard: p.person.stats ? p.person.stats.red_cards : 0,
    }
    return tableRow
  })

  return (
    <OLayout
      tree={tree}
      website={website}
      website_lang={website_lang}
      content={{ parents: breadcrumbs }}
      breadcrumbs={breadcrumbs}
      subnavigation={subnavigation}
      subnavigationDropdown1={subnavigationDropdown1}
      website_theme={website_theme}
      footerPageNodeContent={footerPageNodeContent}
      showAds={!content.hide_ads}
    >
      <TSeo
        website_theme={website_theme}
        website={website}
        website_lang={website_lang}
        content={content}
        full_slug={node.full_slug}
        title={content.title}
      />
      <main className="ef-content ef-content--squad">
        <header className="ef-content__header">
          <div className="ef-container ef-container--l">
            <h1 className="ef-heading ef-heading--2">{content.headline}</h1>
          </div>
        </header>

        <section className="ef-content__block ef-content__block--data-table">
          <div className="ef-container ef-container--l">
            <OTeamStatsTable tableData={tableData} />
          </div>
        </section>
        <OStadiumAdBlock type="stadium_ad_stats_block" />
      </main>

      <MShare title={content.title} website={website} />
    </OLayout>
  )
}

export default TeamStatisticsPageTemplate
